import { graphql } from "gatsby";
import React from "react";
import Audit from '../components/audit'
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import { Container } from "@mui/material"
import SEO from "../components/seo";
import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    audit: sanityAudit(id: { eq: $id }) {
      id
      name
      hidden
      logo {
        _key
        _type
        _rawAsset
        _rawHotspot
        _rawCrop
      }
      slug {
        _key
        _type
        current
      }
      website
      commissioned
      status
      description {
        _key
        _type
        style
        list
        _rawChildren
      }
      contracts {
        issues {
          _key
          _type
          description
          severity
          status
        }
        address
        _type
        _key
      }
      auditReport {
        _key
        _type
        _rawAsset
      }
      category
    }
  }
`;

const AuditTemplate = (props) => {
  const { data, errors } = props;
  const audit = data && data.audit;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {audit && (
        <SEO
          title={audit.name || "Untitled"}
          description={toPlainText(audit.description)}
          image={audit.logo}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {audit && <Audit {...audit} />}
    </Layout>
  );
};

export default AuditTemplate;
