import React from "react";
import { imageUrlFor } from "../lib/image-url";
import {
  Container,
  Typography,
  Avatar,
  Stack,
  Grid,
  Button,
  Chip,
} from "@mui/material";
import theme from "../gatsby-theme-material-ui-top-layout/theme";
import { Box } from "@mui/system";
import { PieChart } from "react-minimal-pie-chart";
import { TbFileDescription } from "react-icons/tb";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "gatsby";
import { getUrlfromId } from "../lib/helpers";

const Audit = (props) => {
  let totalIssues = [
    { severity: "high", value: 0, resolved: 0, color: "crimson" },
    { severity: "medium", value: 0, resolved: 0, color: "darkorange" },
    { severity: "low", value: 0, resolved: 0, color: "gold" },
    { severity: "informational", value: 0, resolved: 0, color: "steelblue" },
  ];

  let issuesCount = 0;

  let rows = [];

  let columns = [
    { field: "severity", headerName: "Severity", flex: 1 },
    { field: "issue", headerName: "Issue", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
  ];

  props.contracts.map((contract) => {
    contract.issues.map((issue) => {
      rows.push({
        id: issue._key,
        issue: issue.description,
        severity: issue.severity,
        status: issue.status,
      });
      totalIssues.map((t) => {
        if (t.severity === issue.severity.toLowerCase()) {
          totalIssues[totalIssues.indexOf(t)].value++;
          issuesCount++;
          if (issue.status.toLowerCase() === "resolved") {
            totalIssues[totalIssues.indexOf(t)].resolved++;
          }
        }
      });
    });
  });

  return (
    <>
      <Container maxWidth="md" sx={{ mb: 10 }}>
        <Grid
          container
          my={5}
          spacing={3}
          sx={{ flexDirection: { sm: "row", xs: "column" } }}
        >
          <Grid item>
            {props.logo && (
              <Avatar
                sx={{ height: "64px", width: "64px", borderRadius: 0, padding: "8px" }}
                src={imageUrlFor(props.logo._rawAsset._ref)}
                alt={props.name}
              />
            )}
          </Grid>
          <Grid item>
            <Typography variant="h4" fontWeight="bold" color="white">
              {props.name && props.name}
            </Typography>
            <Typography variant="subtitle" color="white">
              {props.website && (
                <a
                  href={props.website}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {props.website}
                </a>
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid spacing={3} container justifyContent="space-between">
          {props.description && (
            <Grid item xs={12} sm={6}>
              <Typography
                color={theme.palette.secondary.light}
                variant="subtitle2"
              >
                Description
              </Typography>
              <Typography color="white">
                {props.description[0]._rawChildren[0].text}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={5}>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={6}>
                <Typography
                  color={theme.palette.secondary.light}
                  variant="subtitle2"
                >
                  Commissioned
                </Typography>
                <Typography color="white" variant="h6">
                  {props.commissioned}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  color={theme.palette.secondary.light}
                  variant="subtitle2"
                >
                  Status
                </Typography>
                <Typography color="white" variant="h6">
                  {props.status}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color={theme.palette.secondary.light}
                  variant="subtitle2"
                >
                  Tags
                </Typography>
                <Typography color="white" variant="h6">
                  {props.category.map((cat) => {
                    return (
                      <Chip
                        key={cat}
                        sx={{ marginRight: 1 }}
                        size="small"
                        color="secondary"
                        variant="outlined"
                        label={cat}
                      />
                    );
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth={false}
        sx={{
          background: "white",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          py: 10,
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            height: "100%",
            minHeight: "500px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {props.status.toLowerCase() === "completed" ?
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  color={theme.palette.background.default}
                >
                  Audit summary
                </Typography>
              </Grid>
              <Grid item>
                {props.auditReport &&
                  <a style={{ textDecoration: "none" }} href={getUrlfromId(props.auditReport._rawAsset._ref)} target="_blank" rel="noreferrer">
                    <Button
                      startIcon={<TbFileDescription />}
                      color="error"
                      variant="contained"
                      sx={{ textTransform: "capitalize", mt: 3, }}

                    >
                      View Full Report
                    </Button></a>
                }

              </Grid>
            </Grid>
            : <Grid item textAlign="center"><Typography variant="h5">We're working hard to<br /> finalize this audit</Typography></Grid>}
          <Grid container>
            {props.status.toLowerCase() === "completed" && (
              <Grid item xs={12}>
                <Grid
                  container
                  mt={5}
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <Grid item p={5} xs={12} sm={7}>
                    <PieChart
                      animate
                      style={{ height: "100%", maxHeight: "320px" }}
                      data={totalIssues}
                      paddingAngle={1}
                      lineWidth={8}
                      label={({ x, y, dataIndex }) => (
                        <>
                          {dataIndex > 0 ? null : (
                            <>
                              <text
                                key={dataIndex}
                                x={x}
                                y={y - 8}
                                dominantBaseline="central"
                                textAnchor="middle"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Space Mono",
                                  fill: "#1A374D",
                                }}
                              >
                                {issuesCount}
                              </text>
                              <text
                                x={x}
                                y={y + 8}
                                dominantBaseline="central"
                                textAnchor="middle"
                                style={{
                                  fontSize: "8px",
                                  fontWeight: "300",

                                  fill: "#1A374D",
                                }}
                              >
                                total issues
                              </text>
                            </>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Stack>
                      {totalIssues.map((tIssue) => {
                        return (
                          <Stack
                            color="#1A374D"
                            key={tIssue.severity}
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              textTransform="capitalize"
                            >
                              <Box
                                height={16}
                                width={16}
                                bgcolor={tIssue.color}
                                marginRight={2}
                                sx={{ opacity: 0.85 }}
                              />{" "}
                              {tIssue.severity}
                            </Stack>
                            <Stack direction="row" spacing={1}>
                              {tIssue.value > 0 ? (
                                <>
                                  <Typography fontWeight="bold">
                                    {tIssue.value}
                                  </Typography>
                                  <Typography>
                                    ({tIssue.resolved} resolved)
                                  </Typography>
                                </>
                              ) : (
                                <Typography>-</Typography>
                              )}
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {props.status.toLowerCase() === "completed" &&
            <Grid container>
              <Grid item xs={12} mt={5}>
                <DataGrid
                  getRowHeight={() => 'auto'}
                  density="compact"
                  pageSize={5}
                  autoHeight
                  rows={rows}
                  columns={columns}
                />
              </Grid>
            </Grid>
          }
        </Container>
      </Container>
    </>
  );
};

export default Audit;
